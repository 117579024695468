import { getAssociatedTokenAddress } from "@solana/spl-token"
import { PublicKey } from "@solana/web3.js"
import axios from "axios"

export const roundToDecimals = (number, decimals) => { 
    return parseFloat(number.toFixed(decimals))
}

export const isValidKey = (key) => {
    return key ? key !== '' && key.length >= 32 && key.length <= 44 : false
}

// export const toDataURL = url => fetch(url)
//       .then(response => response.blob())
//       .then(blob => new Promise((resolve, reject) => {
//       const reader = new FileReader()
//       reader.onloadend = () => resolve(reader.result)
//       reader.onerror = reject
//       reader.readAsDataURL(blob)
//      }))


     export const toDataURL = url => fetch(url)
      .then(response => response.blob())
      .then(blob => getResizedImageDataUrl(blob, 600))


    export function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
        u8arr[n] = bstr.charCodeAt(n);
        }
      return new File([u8arr], filename, {type:mime});
     }

     function getResizedImageDataUrl(file, width = undefined, height = undefined, quality = 0.7) {
      return new Promise((resolve) => {
          let image = new Image();
          image.src = URL.createObjectURL(file);
          image.onload = _ => {
              let imageWidth = image.width;
              let imageHeight = image.height;
              let canvas = document.createElement('canvas');

              // resize the canvas and draw the image data into it
              if (width && height) {
                  canvas.width = width;
                  canvas.height = height;
              } else if (width) {
                  canvas.width = width;
                  canvas.height = Math.floor(imageHeight * width / imageWidth)
              } else if (height) {
                  canvas.width = Math.floor(imageWidth * height / imageHeight);
                  canvas.height = height;
              } else {
                  canvas.width = imageWidth;
                  canvas.height = imageHeight
              }

              var ctx = canvas.getContext("2d");
              ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

              let dataUrl = canvas.toDataURL("image/jpeg", quality);
              resolve(dataUrl);
          }
      }).then(dataUrl => {
          return dataUrl;
      })
  }







     export function inputClass(disable, notValid = false){
        return disable ? "dark:bg-jacarta-800 bg-jacarta-100 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-200 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
        : notValid ? "dark:bg-jacarta-700 border-jacarta-100 dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white invalid"
        : "dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
     }
     
     export const formatNumber = (num) => {
        return num > 10000 ? (num/1000).toFixed(2) + "K" : num 
      }


      export const formatWallet = (wallet) => {
        return wallet.slice(0, 10) +
        "..." +
        wallet.slice(
          wallet.length - 6,
          wallet.length
        )
      }

      export const formatWalletSmall = (wallet) => {
        return wallet.slice(0, 4) +
        "..." +
        wallet.slice(
          wallet.length - 3,
          wallet.length
        )
      }

      export const formatWalletXSmall = (wallet) => {
        return wallet.slice(0, 3) +
        "..." +
        wallet.slice(
          wallet.length - 2,
          wallet.length
        )
      }

     export const getSolscanLink = (id, type, net) => { 
        return `https://solscan.io/${type}/${id}?cluster=${net}`
    }


    export const routeCheckSoloApp = (projectId, rest, paramLink = false) => {
      return process.env.NEXT_PUBLIC_SOLO_WEBSITE === 'yes' ? !paramLink ? `/${rest}` : `/${rest}` : !paramLink ? `/${projectId}/${rest}` : `/${projectId}?${rest}`;
    }

    export const getMetadataToken = async (key) => {
      console.log(key)
      const result = await axios.get(`/api/getMetadataToken?key=${key}`)
      if(!result.data.error){
        return result.data.data
      }else{
        return null
      }
    }

    export const checkIfTokenPresentInWallet = async (connection, mintkey, owner) => {
      const mintPubKey = new PublicKey(mintkey)
      const rewardAta = await getAssociatedTokenAddress(
        mintPubKey,
        owner
      )
      const rewardAccount = await connection.getAccountInfo(
        rewardAta
      )
      let balance = null
          if(rewardAccount){
              balance = await connection.getTokenAccountBalance(rewardAta)
              return balance.value.uiAmount > 0 ? true : false
          }else{
              return false
          }
    }
    