import { useWallet } from '@solana/wallet-adapter-react'
import { signOut } from 'next-auth/react'
import Link from 'next/link'
import React, { useContext, useEffect, useState } from 'react'
import { BaseContext } from '../contexts/BaseProvider'
import { formatNumber, roundToDecimals } from '../utils/generalUtils'
import UserId from './userId'


export default function ProfileDropdown({profileShow, setProfileShow}) {
    const {publicKey, disconnect} = useWallet()
    const {walletBalance} = useContext(BaseContext)

    
    const disconnectAll = () => { 
      signOut("discord")
      disconnect()
     }
    // useEffect(() => {
    //   if(walletBalance && walletBalance.length > 0){
    //     const walletBalanceObj = walletBalance.reduce((a, v) => ({ ...a, [v.symbol]: v}), {}) 
    //     setWalletBalanceObj(walletBalanceObj)
    //   }
    // }, [walletBalance])
    
const WalletBalanceItem = ({token, key}) => (
  <div key={key} className="flex items-center gap-1 my-1">
  <img src={token.icon} className="w-6 h-6"/>
  <span className={`${token.symbol === "SOL" ? "text-green":"text-black"} text-md font-bold`}>{formatNumber(roundToDecimals(token.balance, 2))} {token.symbol}</span>
</div>
)

  return (
    <div
    className={
      profileShow
        ? "dropdown-menu border border-accent dark:bg-jacarta-800 group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 !top-[85%] !left-auto z-10 min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 py-4 px-2 lg:shadow-2xl show lg:visible lg:opacity-100"
        : "dropdown-menu border border-accent dark:bg-jacarta-800 group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 !top-[85%] !left-auto z-10 min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 py-4 px-2 lg:shadow-2xl hidden lg:invisible lg:opacity-0"
    }
    onMouseEnter={() => setProfileShow(true)}
    onMouseLeave={() => setProfileShow(false)}
  >
    <UserId
      classes="js-copy-clipboard font-display text-jacarta-700 my-4 flex select-none items-center whitespace-nowrap px-5 leading-none dark:text-white"
      userId={publicKey.toString()}
      shortId={true}
    />

    <div className="dark:border-jacarta-600 border-jacarta-100 mx-5 mb-2 rounded-lg border p-4">
      <span className="dark:text-jacarta-200 text-sm font-medium tracking-tight">
        Balance
      </span>
     {walletBalance && walletBalance.map((x, index) => <div key={index}><WalletBalanceItem token={x}/></div>)}
    </div>
    <Link href="#">
      <a className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
        </svg>
        <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">
          My Profile
        </span>
      </a>
    </Link>
    <div onClick={disconnectAll} className="cursor-pointer">
      <a className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 11V8l-5 4 5 4v-3h8v-2H7z"></path>
        </svg>
        <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">
          Disconnect
        </span>
      </a>
    </div>
  </div>
  )
}


